exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dostepnosc-js": () => import("./../../../src/pages/dostepnosc.js" /* webpackChunkName: "component---src-pages-dostepnosc-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-szukaj-js": () => import("./../../../src/pages/szukaj.js" /* webpackChunkName: "component---src-pages-szukaj-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-autorzy-archive-js": () => import("./../../../src/templates/autorzy-archive.js" /* webpackChunkName: "component---src-templates-autorzy-archive-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-numer-js": () => import("./../../../src/templates/numer.js" /* webpackChunkName: "component---src-templates-numer-js" */),
  "component---src-templates-numery-archive-js": () => import("./../../../src/templates/numery-archive.js" /* webpackChunkName: "component---src-templates-numery-archive-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-tematy-archive-js": () => import("./../../../src/templates/tematy-archive.js" /* webpackChunkName: "component---src-templates-tematy-archive-js" */)
}

